@import url("https://fonts.googleapis.com/css2?family=Newsreader:ital,opsz,wght@0,6..72,300;0,6..72,400;0,6..72,500;1,6..72,300;1,6..72,400;1,6..72,500&display=swap");

.content-container {
  max-width: 60%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .content-container {
    max-width: 90%;
  }
}

.medium-style-content {
  font-family: "Newsreader", sans-serif;
  font-size: 1.3rem;
  line-height: 1.78rem;
  color: #333;
  background-color: #fff;
}

.medium-style-content h1,
.medium-style-content h2,
.medium-style-content h3 {
  margin-top: 1.3em;
}

.medium-style-content p {
  margin.medium-style-content p {
    margin-bottom: 1.3em;
    text-indent: 50px;
    text-align: justify;
  }
}

.medium-style-content blockquote {
  margin: 1.3em 0;
  padding-left: 1.3em;
  border-left: 3px solid #333;
}

.medium-style-content a {
  color: #333;
  text-decoration: underline;
}

.image-gallery-item {
  /* Your existing styles */
}

.thumbnail {
  width: 50%;
  height: 50%;
  overflow: hidden;
  /* Hide the overflow beyond the square */
}

.image-title {
  font-family: "Newsreader", sans-serif;
  /* Use the 'Newsreader' font */
  font-weight: 500;
  /* Set the font weight to 500 (Medium) */
  font-style: italic;
  /* Apply italic style */
  font-size: 1.5rem;
  margin: 0;
}

.image-title-container {
  width: 50%;
  border-bottom: 2px solid black;
}

.thumbnail img {
  width: 45%;
  height: 100%;
  object-fit: cover;
  /* Crop the image within the thumbnail */
}

.modal-content {
  /* Your existing styles */
}

h2 {
  letter-spacing: 0.02ch;
  color: ##222222
}